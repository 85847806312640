.awards {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   margin-top: 3rem;
}

.awardCard {
   flex: 1;
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   min-width: 230px;
   margin: 1rem;
}

.content {
   display: flex;
   flex-direction: column;
   margin-left: 1rem;

   p:first-child {
      color: var(--color-golden);
   }
}

@media screen and (min-width: 1900px) {
   .awardCard {
       min-width: 390px;

       img {
         width: 50px;
         height: 50px;
      }
   }
}

@media screen and (max-width: 450px) {
   .awardCard {
       min-width: 100%;
       margin: 1rem 0;
   }
}