.menu {
   flex-direction: column;
   background: var(--color-black);

   .title {
      margin-bottom: 2rem;
      text-align: center;
   }

   .positions {
      width: 100%;
      margin: 2rem 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;

      .drinks {
         flex: 1;
         width: 100%;
         flex-direction: column;

         p {
            font-family: var(--font-base);
            font-weight: 600;
            font-size: 45px;
            line-height: 58.5px;
            letter-spacing: 0.04em;
            color: var(--color-white);
         }

         .items {
            display: flex;
            flex-direction: column;
            margin: 2rem 0;
            width: 100%;
         }
      }

      .photo {
         width: 410px;
         margin: 0 2rem;

         img {
            width: 100%;
            height: auto;
         }
      }
   }

   .viewMore {
      margin-top: 15px;
   }
}

@media screen and (min-width: 2000px) {
   .menu {
      .positions {
         .photo {
            width: 650px;
      
            img {
               height: 920px;
            }
         }
      }
   }
}

@media screen and (max-width: 1150px) {
   .menu {
      .positions {
         flex-direction: column;
         align-items: center;
         width: 100%;

         .photo {
            margin: 3rem 0;
         }
      }
   }
}

@media screen and (max-width: 650px) {
   .menu {
      .positions {

         .photo {
            width: 100%;
         }

         .drinks p {
            font-size: 35px;
            line-height: 48.5px;
         }
      }
   }
}