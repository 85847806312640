.footer {
   width: 100%;
   position: relative;
   z-index: 1;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-direction: column;
   padding-top: 0;
   z-index: 1;
}

.links {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   margin-top: 5rem;
   padding: 0 2rem;
}

.logo {

   span {
      font-size: 2rem;
      color: white;
   }

   img:first-child {
      width: 210px;
      margin-bottom: 0.75rem;
   }
}

.contacts, .logo, .workingHours{
   flex: 1;
   margin: 1rem;
   text-align: center;
}

.workingHours p:nth-child(2n + 1) {
   margin-bottom: 1rem;
}

.icons {
   margin-top: 0.5rem;

   svg {
      color: var(--color-white);
      margin: 0.5rem;
      font-size: 24px;
      cursor: pointer;

      &:hover {
         color: var(--color-golden);
      }
   }
}

.headtext {
   font-family: var(--font-base);
   color: var(--color-white);
   font-weight: 400;
   letter-spacing: 0.04em;
   text-transform: capitalize;
   font-size: 32px;
   line-height: 41.6px;
   margin-bottom: 1rem;
}

.copyright {
   margin-top: 3rem;
}

@media screen and (min-width: 2000px) {
   .headtext {
       font-size: 51px;
       line-height: 61.6px;
   }
}

@media screen and (max-width: 1150px) {
   .links {
       align-items: center;
       flex-direction: column;
       padding: 0;
   }

   .contacts, .logo, .workingHours {
       margin: 2rem 0;
       width: 100%;
   }
}

@media screen and (max-width: 650px) {
   .footer {
       padding: 0 0 2rem 0;
   }
}

@media screen and (max-width: 350px) {
   .logo img:first-child {
       width: 80%;
   }
}