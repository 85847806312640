.gallery {
   flex-direction: row;
   background: var(--color-black);
   padding: 4rem 0 4rem 6rem;
}

.content {
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   flex-direction: column;
   min-width: 500px;
   padding-right: 2rem;

   p {
      color: var(--color-grey);
      margin-top: 2rem;
   }

   button {
      margin-top: 1rem;
   }
}

.images {
   flex: 1;
   display: flex;
   flex-direction: row;
   max-width: 50%;
   position: relative;
}

.imagesContainer {
   display: flex;
   flex-direction: row;
   width: max-content;
   scrollbar-width: none;  
   overflow-x: scroll;
   -ms-overflow-style: none;

   &::-webkit-scrollbar {
      display: none;
   }
}

.imagesCard {
   position: relative;
   min-width: 301px;
   height: 447px;
   margin-right: 2rem;

   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
      transition: .5s ease;
   }

   &:hover img {
      opacity: .35;
   }

}

.imagesArrows {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 1rem;
   position: absolute;
   bottom: 5%;
}

.arrowIcon {
   color: var(--color-golden);
   font-size: 2rem;
   cursor: pointer;
   background: var(--color-black);
   border-radius: 5px;

   &:hover {
      color: var(--color-white);
   }
}

@media screen and (min-width: 2000px) {
   .content {
      min-width: 1000px;
      padding-right: 4rem;

      & button {
         margin-top: 2rem;
     }
  }
   
   .imagesCard {
       min-width: 400px;
       height: 547px;
   }
}

@media screen and (max-width: 1150px) {
   .gallery {
       flex-direction: column;
   }

   .images {
       max-width: 100%;
       margin: 5rem 0;
   }
}

@media screen and (max-width: 850px) {
   .gallery {
       padding: 4rem 0 4rem 4rem;
   }
}

@media screen and (max-width: 650px) {
   .gallery {
       padding: 4rem 0 4rem 2rem;
   }  
   
   .content {
       min-width: 100%;
   }

   .imagesCard {
       min-width: 240px;
       height: 320px;
   }
}