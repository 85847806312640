.burger {
   display: none;

   .body {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: var(--color-black);
      transition: all .7s ease 0s;
      flex-direction: column;
      z-index: 5;
      animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;  
      -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
   }

   .links {
      list-style: none;

      li {
         margin: 2rem;
         cursor: pointer;
         color: var(--color-golden);
         font-size: 2rem;
         text-align: center;
         font-family: var(--font-base);
         transition: all .7s ease 0s;

         &:hover {
            color: var(--color-white);
         }
      }
   }

   .closeButton {
      font-size: 27px;
      color: var(--color-golden);
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
   }
}

@media screen and (max-width: 1150px) {

   .burger {
      display: flex;
   }
}

@keyframes slide-bottom {
   0% {
     -webkit-transform: translateY(-25%);
             transform: translateY(-25%);
   }
   100% {
     -webkit-transform: translateY(0);
             transform: translateY(0);
   }
 }

@-webkit-keyframes slide-bottom {
   0% {
     -webkit-transform: translateY(-25%);
             transform: translateY(-25%);
   }
   100% {
     -webkit-transform: translateY(0);
             transform: translateY(0);
   }
 }