.content {
   display: flex;
   flex-direction: column;
   width: 100%;
   margin-top: 5rem;

   .quote {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      img {
         width: 47px;
         height: 40px;
         margin: 0 1rem 1rem 0;
      }
   }
}

.signature {
   width: 100%;
   margin-top: 3rem;

   p:first-child {
      font-family: var(--font-base);
      font-weight: 400;
      font-size: 32px;
      line-height: 41.6px;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      color: var(--color-golden);
   }
}