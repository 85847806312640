.aboutUs {
   position: relative;

   .overlay {
      position: absolute;
      inset: 0;

      img {
         width: 400px;
         z-index: 0;
      }
   }
}

.content {
   width: 100%;
   z-index: 2;

   .leftColumn {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      text-align: right;

      img {
         transform: rotate(180deg);
      }
   }

   .centerColumn {
      margin: 2rem 4rem;

      img {
         height: 910px;
      }
   }

   .rightColumn {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
   }

   .leftColumn p,
   .rightColumn p {
      margin: 2rem 0;
      color: var(--color-grey);
   }
}

@media screen and (min-width: 2000px) {
   .centerColumn img {
      height: 1110px;
   }

   .leftColumn p,
   .rightColumn p {
      margin: 4rem 0;
   }
}

@media screen and (max-width: 900px) {
   .content {
      flex-direction: column;

      .centerColumn {
         margin: 4rem 0;
      }
   }
}

@media screen and (max-width: 650px) {

   .aboutUs {
      .overlay img {
         width: 90%;
         height: 320px;
      }
   }
}