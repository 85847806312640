h1.findUs {
   margin-bottom: 3rem;
}

p.openingHours {
   margin: 2rem 0;
   color: var(--color-golden);
}

button.visitUs {
   margin-top: 2rem;
}