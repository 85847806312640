.menuItem {
   width: 100%;
   margin: 1rem 0;
   display: flex;
   flex-direction: column;

   .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
         flex: 1;

         p {
            color: var(--color-golden);
         }
      }

      .dash {
         width: 90px;
         height: 1px;
         background: var(--color-golden);
         margin: 0 1rem;
      }

      .price {
         display: flex;
         justify-content: flex-end;
         align-items: flex-end;
      }
   }

   .subtitle {
      width: 100%;
      margin-top: 0.2rem;

      p {
         color: var(--color-grey);
      }
   }
}