.video {
   height: 100vh;
   position: relative;

   video {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   .background {
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, .65);

      .playButton {
         width: 100px;
         height: 100px;
         border-radius: 50%;
         border: 1px solid var(--color-golden);
         cursor: pointer;
      }
   }
}