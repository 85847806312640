.wrapper {
    width: calc(100vw - 1%);
    height: 100%;
    background-color: var(--color-black);
    position: relative;
    z-index: 2;
}

.newsletter {
    margin: 0 auto;
    width: 50%;
    padding: 2rem 4rem;
    border: 1px solid var(--color-golden);
}

.heading {
    text-align: center;
}

.input {
    flex-direction: row;
    margin-top: 3rem;

    input {
        width: 620px;
        border: 1px solid var(--color-golden);
        border-radius: 5px;
        font-size: 1rem;
        font-family: var(--font-base);
        color: var(--color-white);
        margin-right: 2rem;
        padding: 0.75rem 1rem;
        background: var(--color-black);
    }

    button {
        width: max-content;
    }
}

@media screen and (min-width: 2000px) {
    .input input {
        font-size: 2rem;
    }
}

@media screen and (max-width: 990px) {
    .input {
        flex-direction: column;
        width: 96%;

        input {
            margin: 0 0 2rem 1rem;
            width: 96%;
        }
    }
}

@media screen and (max-width: 650px) {
    .newsletter {
        width: 80%;
        padding: 2rem 0;
    }
}

@media screen and (max-width: 300px) {
    .heading h1 {
        font-size: 32px;
        line-height: 50px;
    }
}