.header {
   background-color: var(--color-black);

   h1 {
      font-family: var(--font-base);
      color: var(--color-golden);
      font-weight: 700;
      letter-spacing: 0.04em;
      text-transform: capitalize;
      line-height: 117px;
      font-size: 90px;
   }

   .image img {
      width: 80%;
   }
}

@media screen and (min-width: 2000px) {
   .header h1 {
       font-size: 180px;
       line-height: 210px;
   }
}

@media screen and (max-width: 1150px) {
   .header h1 {
       font-size: 90px;
   }
}

@media screen and (max-width: 650px) {
   .header h1 {
       font-size: 70px;
       line-height: 87px;
   }
}

@media screen and (max-width: 450px) {
   .header h1 {
       font-size: 50px;
       line-height: 70px;
   }
}
